import React from "react";

export const SidebarComponent = () => {

    return <>
        <div id="sidebar-right" className="fixed-right">
            <a href="https://tienda.antel.com.uy/catalogo/hosting" target="_blank" rel={"noreferrer"}> <img src={"/imgs/banner3.png"} alt="Banner"/></a>
            <a href="https://www.antel.com.uy/empresas/internet-y-fija/planes" target="_blank" rel={"noreferrer"}> <img src={"/imgs/banner2.png"} alt="Banner"/></a>
        </div>
    </>
}