import {
    buildObtenerContactosUsuarioResponse,
    buildObtenerContactoUsuarioResponse,
    buildObtenerCuentasPagoUsuarioResponse,
    buildObtenerTemplateCuentaPagoResponse,
    buildCrearCuentaPagoRequest,
    buildCrearCuentaPagoResponse,
    buildActualizarTemplateCuentaPagoResponse,
    buildActualizarTemplateCuentaPagoRequest,
    buildEliminarCuentaPagoUsuarioResponse,
    buildCrearContactoDominioResponse,
    buildActualizarContactoUsuarioResponse,
    buildObtenerTiposContactoResponse
} from "../dto/CuentaDTOBuilder";
import http from '../support/HttpService';

const REACT_APP_OBTENER_CONTACTO_USUARIO = process.env.REACT_APP_OBTENER_CONTACTO_USUARIO;
const REACT_APP_ACTUALIZAR_CONTACTO_USUARIO = process.env.REACT_APP_ACTUALIZAR_CONTACTO_USUARIO;
const REACT_APP_CREAR_CONTACTO_USUARIO = process.env.REACT_APP_CREAR_CONTACTO_USUARIO;
const REACT_APP_OBTENER_CONTACTOS_USUARIO = process.env.REACT_APP_OBTENER_CONTACTOS_USUARIO;
const REACT_APP_OBTENER_CUENTAS_PAGO_USUARIO = process.env.REACT_APP_OBTENER_CUENTAS_PAGO_USUARIO;
const REACT_APP_OBTENER_TEMPLATE_CUENTA_PAGO = process.env.REACT_APP_OBTENER_TEMPLATE_CUENTA_PAGO;
const REACT_APP_OBTENER_TEMPLATE_CUENTA_PAGO_ASOCIADA = process.env.REACT_APP_OBTENER_TEMPLATE_CUENTA_PAGO_ASOCIADA;
const REACT_APP_CREAR_CONTACTO_DOMINIO = process.env.REACT_APP_CREAR_CONTACTO_DOMINIO;
const REACT_APP_OBTENER_CONTACTO_DOMINIO = process.env.REACT_APP_OBTENER_CONTACTO_DOMINIO;
const REACT_APP_CREAR_CUENTA_PAGO = process.env.REACT_APP_CREAR_CUENTA_PAGO;

const REACT_APP_ASOCIAR_CUENTA_PAGO_VENTA = process.env.REACT_APP_ASOCIAR_CUENTA_PAGO_VENTA;
const REACT_APP_ACTUALIZAR_TEMPLATE_CUENTA_PAGO = process.env.REACT_APP_ACTUALIZAR_TEMPLATE_CUENTA_PAGO;
const REACT_APP_ELIMINAR_CUENTA_PAGO_USUARIO = process.env.REACT_APP_ELIMINAR_CUENTA_PAGO_USUARIO;
const REACT_APP_OBTENER_TIPOS_CONTACTO = process.env.REACT_APP_OBTENER_TIPOS_CONTACTO;

const CuentaService = {

    getContactoPredeterminadoUsuario: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_CONTACTO_USUARIO);
            return buildObtenerContactoUsuarioResponse(response.data);
        } catch (error){
            console.error("Error procesando pedido");
            // this.mostrarMensaje("Ha ocurrido un error al realizar la consulta de contacto de usuario. Intente nuevamente más tarde.", "danger");
            throw error;
        }
    },

    actualizarContactoPredeterminadoUsuario: async function(requestDTO) {
        try {
            const response = await http.post(REACT_APP_ACTUALIZAR_CONTACTO_USUARIO, requestDTO);
            return buildActualizarContactoUsuarioResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
            // this.mostrarMensaje("Ha ocurrido un error al realizar la consulta de contacto de usuario. Intente nuevamente más tarde.", "danger");
            throw error;
        }
    },

    crearContactoPredeterminadoUsuario: async function(requestDTO) {
        try {
            const response = await http.post(REACT_APP_CREAR_CONTACTO_USUARIO, requestDTO);
            return buildActualizarContactoUsuarioResponse(response.data);
        } catch (error){
            console.error(JSON.stringify(error));
            // this.mostrarMensaje("Ha ocurrido un error al realizar la consulta de contacto de usuario. Intente nuevamente más tarde.", "danger");
            throw error;
        }
    },

    getContactosUsuario: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_CONTACTOS_USUARIO);
            return buildObtenerContactosUsuarioResponse(response.data);
        } catch (error){
            console.error("Error procesando pedido");
            throw error;
        }
    },

    getUsuarioDominio: async function(dominioId) {
        try {
            const response = await http.get(REACT_APP_OBTENER_CONTACTO_DOMINIO + dominioId);
            return buildObtenerContactoUsuarioResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    getCuentasPagoUsuario: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_CUENTAS_PAGO_USUARIO);
            return buildObtenerCuentasPagoUsuarioResponse(response.data);
        } catch (error){
            console.error("Error procesando pedido");
            throw error;
        }
    },

    getTemplateCuentaPago: async function(templateCuentaId) {
        try {
            const response = await http.get(REACT_APP_OBTENER_TEMPLATE_CUENTA_PAGO + templateCuentaId);
            return buildObtenerTemplateCuentaPagoResponse(response.data);
        } catch (error){
            console.error("Error procesando pedido");
            throw error;
        }
    },
    getTemplateCuentaPagoAsociada: async function(templateCuentaId) {
        try {
            const response = await http.get(REACT_APP_OBTENER_TEMPLATE_CUENTA_PAGO_ASOCIADA + templateCuentaId);
            return buildObtenerTemplateCuentaPagoResponse(response.data);
        } catch (error){
            console.error("Error procesando pedido");
            throw error;
        }
    },

    crearContactoDominio: async function(dominiosIds, requestDTO) {
        try {
            const response = await http.post(REACT_APP_CREAR_CONTACTO_DOMINIO, requestDTO);
            return buildCrearContactoDominioResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    crearCuentaPago: async function(uuidVenta, templateCuentaPago, formaDePagoActual) {
        try {
            const response = await http.post(REACT_APP_CREAR_CUENTA_PAGO, buildCrearCuentaPagoRequest(uuidVenta, templateCuentaPago, formaDePagoActual));
            return buildCrearCuentaPagoResponse(response.data);
        } catch (error) {
            console.error("Error procesando pedido");
        }
    },

    actualizarTemplateCuentaPago: async function(templateCuentaPagoId, templateCuentaPagoNombre) {
        try {
            const response = await http.post(REACT_APP_ACTUALIZAR_TEMPLATE_CUENTA_PAGO, buildActualizarTemplateCuentaPagoRequest(templateCuentaPagoId, templateCuentaPagoNombre));
            return buildActualizarTemplateCuentaPagoResponse(response.data);
        } catch (error) {
            console.error(JSON.stringify(error));
        }
    },

    eliminarCuentaPagoUsuario: async function(templateCuentaPagoId) {
        try {
            const response = await http.delete(REACT_APP_ELIMINAR_CUENTA_PAGO_USUARIO + templateCuentaPagoId);
            return buildEliminarCuentaPagoUsuarioResponse(response.data);
        } catch (error) {
            console.error(JSON.stringify(error));
        }
    },

    getTiposContacto: async function() {
        try {
            const response = await http.get(REACT_APP_OBTENER_TIPOS_CONTACTO);
            return buildObtenerTiposContactoResponse(response);
        } catch (error) {
            console.error(JSON.stringify(error));
        }
    }

}
export default CuentaService;